<template>


  <div class="home">

    <div id="myCarousel" class="carousel slide mb-6" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" class="active" aria-current="true"
          aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active"
        :style="{ backgroundImage: 'url(' + require('@/assets/images/main0.png') + ')', backgroundSize: 'cover', backgroundPosition: 'center'  }" style="background-size: 1900px 800px; ">
          <!-- <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
            <rect width="100%" height="100%" fill="var(--bs-secondary-color)" />
          </svg> -->
          <div class="container">
            <div class="carousel-caption text-start">
              <!-- <h2 style="margin-bottom: 2rem; font-weight: 600;">スマート体温計</h2>
              <p>家庭用医療機器「ドクターインホーム」を通じて <br>病院診療後の体系的な管理を行ってください。</p> -->
            </div>
          </div>
        </div>
        <div class="carousel-item" :style="{ backgroundImage: 'url(' + require('@/assets/images/main2.png') + ')', backgroundSize: 'cover', backgroundPosition: 'center'   }"
        style="background-size: 1900px 800px; ">
          <div class="container">
            <div class="carousel-caption">
              <h2 style="margin-bottom: 2rem; font-weight: 600;">スマート体温計</h2>
              <p class="opacity-75">家庭用医療機器「ドクターインホーム」を通じて <br>病院診療後の体系的な管理を行ってください。</p>
            </div>
          </div>
        </div>
        <div class="carousel-item" :style="{ backgroundImage: 'url(' + require('@/assets/images/main3.png') + ')', backgroundSize: 'cover', backgroundPosition: 'center'   }"
        style="background-size: 1900px 800px; ">
          <div class="container">
            <div class="carousel-caption">
              <h2 style="margin-bottom: 2rem; font-weight: 600;">扁桃炎カメラ</h2>
              <p class="opacity-75">ドクターインホームの2番目の医療機器である扁桃炎カメラを使用して <br>喉の管理も行えます。</p>
            </div>
          </div>
        </div>
        <div class="carousel-item" :style="{ backgroundImage: 'url(' + require('@/assets/images/main4.png') + ')', backgroundSize: 'cover', backgroundPosition: 'center'  }"
        style="background-size: 1900px 800px; ">
          <div class="container">
            <div class="carousel-caption">
              <h2 style="margin-bottom: 2rem; font-weight: 600;">ドクターインホーム</h2>
              <p>継続的に記録したお子様の記録が見やすく整理されます。 <br>お子様の体温や処方の記録を一目で確認できます。</p>
            </div>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>


    <!-- Marketing messaging and featurettes
================================================== -->
    <!-- Wrap the rest of the page in another container to center all the content. -->

    <div class="container marketing" style="padding-top: 5rem;">

      <div class="row featurette" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
        <div class="col-md-12 what-is">
          <h2 class="featurette-heading fw-normal lh-1" style="margin-top: 10 !important; font-weight: 600 !important; font-size: 30px;">
            子供の健康は毎日管理しなければなりません。
          </h2>
        </div>
        <div class="row" style="justify-content: center;">
          <div class="col-lg-4">
            <img src="@/assets/images/intro-21.png" alt="스마트체온계 제품사진" class="bd-placeholder-img" width="430"
              height="330" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
              <h4 class="fw-normal2">中耳炎</h4>
              <p>聴力低下 / 認知能力低下 / 言語発達遅延</p>
          </div>
          <div class="col-lg-4">
            <img src="@/assets/images/intro-22.png" alt="스마트체온계 제품사진" class="bd-placeholder-img" width="430"
              height="330" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
              <h4 class="fw-normal2">扁桃炎</h4>
              <p>顔の変形 / いびき / 成長遅延 / 中耳炎のリスク増加</p>
          </div>
        </div>
        <div class="col-md-12 what-is">
          <p class="lead">
            中耳炎や扁桃炎の治療時期を逃すと、<span style="font-weight: 600;">副作用や合併症</span>が引き起こされる可能性があります。 <br>継続的な観察と早期治療が、お子様の健康を守る道です。
          </p>
        </div>
      </div>

      <!-- START THE FEATURETTES -->

      <hr class="featurette-divider">

      <div class="row featurette" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
        <div class="col-md-7">
          <h2 class="featurette-heading fw-normal lh-1">全国の <span
              class="text-body-secondary">小児科<br></span>耳鼻咽喉科を足しても内科の半分以下、年々減少傾向</h2>
          <p class="lead">オープンランや予約なしでは訪問しにくい病院 <br>自宅で自分の手で5分で終わり！ドクターインホームを体験してみて下さい。</p>
        </div>
        <div class="col-md-5">
          <!-- <svg class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-bg)"/><text x="50%" y="50%" fill="var(--bs-secondary-color)" dy=".3em">500x500</text></svg> -->
          <!-- <img src="@/assets/images/item-1.jpg" alt="스마트체온계 제품사진" class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"> -->
          <div class="card-hos">
            <div class="card-body-hos pb-0">
              <h5 class="card-title-hos">全国の病院数 <span>| 2019 基準 / 医療施設調査 令和元年医療施設（動態）調査 全国編 出処</span></h5>

              <div id="trafficChart" style="min-height: 400px;" class="echart"></div>
            </div>
          </div>
        </div>
      </div>

      <hr class="featurette-divider">

      <div class="row featurette" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
        <div class="col-md-7 order-md-2">
          <h2 class="featurette-heading fw-normal lh-1">減り続ける <span class="text-body-secondary">小児科専攻の <br></span></h2>
          <p class="lead">大人にとって軽い熱感覚も致命的な私たちの子供 <br>病院の訪問が難しい場合でも、自宅で継続的なケアが必要です。</p>
        </div>
        <div class="col-md-5 order-md-1" style="text-align: center;">
          <!-- <img src="@/assets/images/item-2.png" alt="스마트체온계 제품사진" class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false" style="max-width: 250px; height: auto;"> -->
          <div id="reportsChart">
            <p style="text-align: left; font-size: 14px;">出処 : 一般社団法人日本専門医機構</p>
            <p style="text-align: right; font-size: 15px;">割合 単位 : 人</p>
          </div>
        </div>
      </div>

      <hr class="featurette-divider">

      <div class="row featurette" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
        <div class="col-md-12 what-is">
          <h2 class="featurette-heading fw-normal lh-1" style="margin-top: 10 !important; font-weight: 600 !important; "> 
            <span style="color: #FE6F0F;">スマート</span>に<span style="color: #FE6F0F;">ドクターインホーム</span>を利用しましょう。
          </h2>
        </div>
        <div class="col-md-12 what-is">
          <img src="@/assets/images/item-3eng.png" alt="스마트체온계 제품사진"
            class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="700" height="500"
            aria-label="Placeholder: 700x500" preserveAspectRatio="xMidYMid slice" focusable="false">
        </div>
        <div class="col-md-12 what-is">
          <p class="lead">
            内視鏡カメラとアプリの連携により、写真の移動が簡単です。 お子様の<span style="font-weight: 600;">中耳炎</span>や<span style="font-weight: 600;">扁桃炎</span>の進行段階をどこでも確認できます。
          </p>
        </div>
      </div>

      <hr class="featurette-divider">

      <div class="row" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
        <div class="col-lg-4">
          <img src="@/assets/images/intro-1.png" alt="스마트체온계 제품사진" class="bd-placeholder-img" width="360" height="260"
            aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
          <h4 class="fw-normal2">家族と一緒に</h4>
          <p>家族構成員であれば誰でも記録できます。
            <br>お子様の症状を記録し、リアルタイムで確認してください。</p>
        </div>
        <div class="col-lg-4">
          <img src="@/assets/images/intro-4.png" alt="스마트체온계 제품사진" class="bd-placeholder-img" width="360" height="260"
            aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
          <h4 class="fw-normal2">専門的なケア</h4>
          <p>内視鏡カメラで改善状況を確認できます。
            <br>継続的な管理で、簡単な治療のタイミングを逃さないようにしましょう。</p>
        </div>
        <div class="col-lg-4">
          <img src="@/assets/images/intro-2.png" alt="스마트체온계 제품사진" class="bd-placeholder-img" width="360" height="260"
            aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
          <h4 class="fw-normal2">健康管理記録</h4>
          <p>日付別・時間別の記録で健康チェックが簡単です。 <br>服薬記録を活用して、お子様に合った薬を見つけてください。</p>
        </div>
      </div>

      <hr class="featurette-divider">

      <!-- /END THE FEATURETTES -->

      <!-- 팝업창 / 메인 화면에만 등장 -->
      <div v-if="modalCheck" class="modal-wrap">
        <div class="modal-overlay" id="modalOverlay"></div>
        <div class="modal-container">
          <img :src="currentImage" alt="팝업창" class="modal-image">
          <div class="modal-btn">
            <button @click="closeModal">Close</button>
            <button @click="closeTodayPopup">Close for today</button>
          </div>
        </div>
      </div>

    </div><!-- /.container -->




  </div>
</template>
<script>
// import axios from 'axios';
// import {reactive} from 'vue';
import { onMounted } from 'vue';
import AOS from 'aos';
import * as echarts from 'echarts';
import ApexCharts from 'apexcharts';


export default {
  name: 'Home',
  setup() {
    onMounted(() => {
      AOS.init({
        // Optional settings
        duration: 450,
      });
    });
  },
  data() {
    return {
      modalCheck: false,
      todayPopupClose: false,
      images: [
        require('@/assets/images/popup.jpg'),
        // require('@/assets/images/popup-4.png'),
        // require('@/assets/images/popup-3.png'),
        // require('@/assets/images/popup-5.png'),
      ],
      currentImageIndex: 0
    };
  },
  computed: {
    currentImage() {
      return this.images[this.currentImageIndex];
    }
  },
  mounted() {
    this.startSlideshow();
    this.drawChart();

    // 화면이 로드될 때 모달을 열기 위해 modalCheck 값을 true로 설정
    if (!this.backgroundTodayPopupClosed) {
      this.backgroundModalCheck = true;
    }
    if (!this.todayPopupClose) {
      this.modalCheck = true;
    }

    new ApexCharts(document.querySelector("#reportsChart"), {
      series: [{
        name: '専門研修プログラムを履修している専攻医数',
        data: [497, 516, 546, 607, 639, 665],
      },
      {
        name: '小児科専門研修プログラムを履修している専攻医数',
        data: [24, 26, 36, 38, 38, 33],
      },
      ],
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false
        },
      },
      markers: {
        size: 4
      },
      colors: ['#4154f1', '#2eca6a', '#ff771d'],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.3,
          opacityTo: 0.4,
          stops: [0, 90, 100]
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      xaxis: {
        type: 'datetime',
        categories: ["2018", "2019", "2020", "2021", "2022", "2023"]
      },
      tooltip: {
        x: {
          format: 'yyyy'
        },
      }
    }).render();
  },
  methods: {
    closeModal() {
      this.modalCheck = false;
    },
    closeTodayPopup() {
      this.todayPopupClose = true;
      this.modalCheck = false;

      // 현재 시간에서 24시간을 더한 만료 시간을 설정합니다.
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + 24 * 60 * 60 * 1000);
      const expires = "expires=" + expirationDate.toUTCString();

      // 쿠키를 설정합니다.
      document.cookie = "todayPopupClose=true;" + expires + ";path=/";
    },
    startSlideshow() {
      setInterval(() => {
        this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
      }, 1800); //1.8초마다 이미지 변경
    },
    
    drawChart() {
      const chart = echarts.init(document.getElementById('trafficChart'));
      chart.setOption({
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [{
          name: '比較',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '18',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [{
            value: 2539,
            name: '全国小児科数'
          },
          {
            value: 1957,
            name: '全国耳鼻咽喉科数'
          },

          {
            value: 6705,
            name: '全国内科数'
          }
          ]
        }]
      });
    }
  },
  created() {
    const cookies = document.cookie.split(';').map(cookie => cookie.trim());
    for (const cookie of cookies) {
      const [name, value] = cookie.split('=');
      if (name === 'todayPopupClose' && value === 'true') {
        this.todayPopupClose = true;
      }
      if (name === 'backgroundTodayPopupClosed' && value === 'true') {
        this.backgroundTodayPopupClosed = true;
      }
    }
  },
}


</script>
<style scoped>
@import 'aos/dist/aos.css';

/* 모달 스타일 */
.background-modal-container {
  position: absolute;
  top: 12%;
  left: 14%;
  width: 30%;
  max-width: 500px;
  max-height: 90%;
  background: inherit;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: 999;
}

.modal-wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 배경 불투명도 설정 */
  z-index: 998; /* 모달 위로 오게 설정 */
}

.modal-container {
  position: absolute;
  top: 12%;
  left: 37%;
  width: 30%;
  max-width: 500px;
  max-height: 90%;
  overflow-y: auto;
  background: inherit;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: 1000; /* 오버레이 위로 오게 설정 */
}

.modal-image,
.background-modal-image {
  width: 100%;
  height: auto;
}

.popup-btn {
  background-color: #9b9fb9;
  width: 100%;
}

.modal-btn {
  background-color: transparent;
  width: 100%;
}

.popup-btn button {
  border: none;
  border-radius: 8px;
  margin: 10px;
  background-color: #9b9fb9;
  color: #fff;
}

.modal-btn button {
  border: none;
  border-radius: 8px;
  margin: 10px;
  background-color: transparent;
  color: #fff;
}

@media (max-width: 768px) {
  .background-modal-container,
  .modal-container {
    width: 80%;
    left: 10%;
  }
}

@media (max-width: 480px) {
  .background-modal-container,
  .modal-container {
    width: 90%;
    left: 5%;
  }
}



.image-container {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.responsive-image {
  max-width: 100%;
  height: auto;
}

.what-is {
  text-align: center;
  padding-bottom: 4rem;
}

hr {
  border-top: none;
}

.fw-normal2 {
  padding-top: 2rem;
  font-weight: 600;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #44423b;
  border-radius: 50%; /* 둥근 모양으로 변경 */
}

.carousel-indicators button {
  background-color: #44423b;
  /* 기본 버튼 색상 */
}


.carousel-item {
  height: 50rem;
}

.carousel-caption {
  width: 45%;
  bottom: 31rem;
  color: #27272A;
  text-align: left;
}

/* Card */
.card-hos {
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.card-header-hos,
.card-footer-hos {
  border-color: #ebeef4;
  background-color: #fff;
  color: #798eb3;
  padding: 15px;
}

.card-title-hos {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: #012970;
  font-family: "Poppins", sans-serif;
}

.card-title-hos span {
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}

.card-body-hos {
  padding: 0 20px 20px 20px;
}

.card-img-overlay {
  background-color: rgba(255, 255, 255, 0.6);
}
</style>